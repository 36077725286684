var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"cms-create-promotion","title":"New Promotion","no-close-on-backdrop":"","size":"lg"},on:{"ok":_vm.onOk}},[_c('validation-observer',{ref:"cmsPromotionForm"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('validation-provider',{attrs:{"name":"title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Title"}},[_c('b-form-input',{attrs:{"id":"title","state":errors[0] ? false : null},model:{value:(_vm.createPromotionForm.title),callback:function ($$v) {_vm.$set(_vm.createPromotionForm, "title", $$v)},expression:"createPromotionForm.title"}})],1)]}}])}),_c('validation-provider',{attrs:{"name":"image_src","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Image"}},[_c('CmsFileSelect',{attrs:{"value":_vm.createPromotionForm.imageSrc,"state":errors[0] ? false : null},on:{"update":function (value) { return (_vm.createPromotionForm.imageSrc = value || ''); }}})],1)]}}])}),_c('validation-provider',{attrs:{"name":"image_src","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Content"}},[_c('vue-editor',{attrs:{"state":errors[0] ? false : null},model:{value:(_vm.createPromotionForm.content),callback:function ($$v) {_vm.$set(_vm.createPromotionForm, "content", $$v)},expression:"createPromotionForm.content"}})],1)]}}])}),_c('validation-provider',{attrs:{"name":"position","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Position"}},[_c('b-form-input',{attrs:{"id":"position","state":errors[0] ? false : null},model:{value:(_vm.createPromotionForm.position),callback:function ($$v) {_vm.$set(_vm.createPromotionForm, "position", $$v)},expression:"createPromotionForm.position"}})],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }